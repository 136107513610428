import { onCleanup, type Accessor } from 'solid-js';

declare module 'solid-js' {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface DirectiveFunctions {
      // use:clickOutside
      clickOutside: typeof clickOutside;
    }
  }
}

export function clickOutside(
  element: HTMLElement,
  accessor: Accessor<(() => void) | undefined>,
) {
  const onClick = (event: MouseEvent) => {
    if (!element.contains(event.target as Element)) {
      accessor()?.();
    }
  };

  document.body.addEventListener('click', onClick);

  onCleanup(() => {
    document.body.removeEventListener('click', onClick);
  });
}
