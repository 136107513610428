import { gql } from '~/__gql-generated__';
import { query, createAsync } from '@solidjs/router';
import { createSignal, For, onCleanup, onMount } from 'solid-js';

import rootStyles from '~/components/Root.module.scss';
import styles from '~/components/SocialLinks.module.scss';
import SmartA from '~/components/SmartA';
import { socialLabels } from '~/components/UserPage';
import { client } from '~/utils/graphql';

const OUT_SOCIAL_LINKS = gql(`
  query OurSocialLinks {
    ourSocialLinks(options: { sort: [{ weight: ASC }] }) {
      type
      link
    }
  }
`);

const getOurSocialLinks = query(async () => {
  'use server';

  const { data } = await client.query({ query: OUT_SOCIAL_LINKS });

  return data.ourSocialLinks;
}, 'ourSocialLinks');

export default function SocialLinks() {
  const data = createAsync(() => getOurSocialLinks());
  const [colorScheme, setColorScheme] = createSignal('light');

  const handleColorScheme = (event: Event) => {
    const { detail: colorScheme } = event as CustomEvent<string>;
    resolveColorScheme(colorScheme);
  };

  const resolveColorScheme = (colorScheme: string) => {
    setColorScheme(
      colorScheme === 'system'
        ? matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
        : colorScheme,
    );
  };

  onMount(() => {
    globalThis.addEventListener('colorschemechange', handleColorScheme);
    setColorScheme(
      document.documentElement.classList.contains('dark') ? 'dark' : 'light',
    );

    onCleanup(() => {
      globalThis.removeEventListener('colorschemechange', handleColorScheme);
    });
  });

  return (
    <ul
      classList={{ [rootStyles['clean-list']]: true, [styles.wrapper]: true }}
    >
      <For each={data()}>
        {link => (
          <li>
            <SmartA href={link.link}>
              <img
                loading="lazy"
                alt={socialLabels[link.type]}
                src={`/assets/brands/${link.type.toLowerCase()}/${colorScheme()}.svg`}
              />
            </SmartA>
          </li>
        )}
      </For>
    </ul>
  );
}
