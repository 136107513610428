import Tooltip from '@corvu/tooltip';
import { useLocation } from '@solidjs/router';
import { clientOnly } from '@solidjs/start';
import { createEffect, createSignal } from 'solid-js';

import headerStyles from '~/components/Header.module.scss';
import rootStyles from '~/components/Root.module.scss';
import styles from '~/components/Search.module.scss';

const SearchResults = clientOnly(() => import('~/components/SearchResults'));

interface SearchProps {
  readonly onClose: (path?: string) => void;
}

export default function SearchForm(props: SearchProps) {
  const [query, setQuery] = createSignal('');
  const [searchFieldRef, setSearchFieldRef] = createSignal<HTMLInputElement>();

  const location = useLocation();

  createEffect(() => {
    if (location.hash === '#site-search') {
      setQuery('');
    }
  });

  return (
    <>
      <search id="site-search" class={styles['search-form']}>
        <form
          action="https://www.google.com/webhp"
          class={rootStyles.container}
        >
          <input
            ref={setSearchFieldRef}
            type="hidden"
            name="sitesearch"
            value="radiojhero.com"
          />
          <Tooltip
            placement="bottom"
            strategy="fixed"
            openDelay={200}
            hoverableContent={false}
            floatingOptions={{
              offset: 10,
              flip: true,
              shift: true,
            }}
          >
            <Tooltip.Trigger
              as="a"
              class={headerStyles.button}
              href="#search-button"
              aria-label="Voltar"
              onClick={(event: Event) => {
                event.preventDefault();
                props.onClose();
              }}
            >
              <IconTablerChevronLeft />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content>
                Voltar
                <Tooltip.Arrow size={10} />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip>
          <input
            id="q"
            type="search"
            name="q"
            value={query()}
            placeholder="Faça sua busca..."
            onKeyDown={event => {
              if (event.key === 'Escape') {
                props.onClose();
              }
            }}
            onInput={event => {
              setQuery(event.target.value);
            }}
          />
        </form>
      </search>
      <SearchResults
        query={query()}
        searchFieldRef={searchFieldRef}
        onNavigate={path => {
          props.onClose(path);
        }}
      />
    </>
  );
}
