import type { JSX } from 'solid-js';

import styles from '~/components/FooterButtonGroup.module.scss';

export default function FooterButtonGroup(
  props: JSX.HTMLAttributes<HTMLDivElement>,
) {
  return (
    <div
      classList={{
        [styles.wrapper]: true,
        ...(props.class ? { [props.class]: true } : {}),
        ...props.classList,
      }}
      {...props}
    />
  );
}
