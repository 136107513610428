import { MetaProvider } from '@solidjs/meta';
import { type RouteSectionProps } from '@solidjs/router';
import { clientOnly } from '@solidjs/start';
import 'pollen-css/pollen.css';
import { SolidNProgress } from 'solid-progressbar';
import { Suspense } from 'solid-js';

import '~/code-highlight.scss';
import BackToTop from '~/components/BackToTop';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import PlayerContext from '~/components/PlayerContext';
import '~/components/Root.module.scss';

const Player = clientOnly(() => import('~/components/player'));
const SongLyricsModal = clientOnly(
  () => import('~/components/player/SongLyricsModal'),
);
const SongRequestModal = clientOnly(
  () => import('~/components/SongRequestModal'),
);

export default function Root(props: RouteSectionProps) {
  return (
    <MetaProvider>
      <PlayerContext>
        <SolidNProgress
          color="light-dark(var(--color-sky-400), var(--color-sky-600))"
          stopDelayMs={0}
          options={{ showSpinner: false }}
        />

        <Suspense>
          <Header />
          {props.children}
          <Footer />
        </Suspense>
        <BackToTop />
        <Player />
        <SongLyricsModal />
        <SongRequestModal />
      </PlayerContext>
    </MetaProvider>
  );
}
