import Tooltip from '@corvu/tooltip';
import { query, createAsync, useNavigate } from '@solidjs/router';
import { For, type JSX } from 'solid-js';

import { gql } from '~/__gql-generated__';
import styles from '~/components/Header.module.scss';
import rootStyles from '~/components/Root.module.scss';
import Search from '~/components/Search';
import SmartA from '~/components/SmartA';
import { clickOutside } from '~/utils/clickOutside';
import { generateRetinaThumbs } from '~/utils/generateThumbs';
import { client } from '~/utils/graphql';
import { isChristmas } from '~/utils/isChristmas';
import { webkitFocusTrick } from '~/utils/webkitFocusTrick';

const QUERY = gql(`
  query Query {
    latestRadioShows {
      title
      slug
    }
    latestCategories {
      title
      slug
    }
  }
`);

const getQuery = query(async () => {
  'use server';

  const { data } = await client.query({ query: QUERY });
  return data;
}, 'menuData');

export default function Header() {
  const data = createAsync(() => getQuery());

  const backFromSearch = (path?: string) => {
    navigate(-1);

    const element = document.querySelector<HTMLElement>('#site-search');
    const button = document.querySelector<HTMLButtonElement>('#search-button');
    if (!element || !button) {
      return;
    }

    element.style.visibility = '';
    setTimeout(() => {
      button.focus();

      if (path) {
        navigate(path);
      }
    }, 50);
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const isClosed = () => {
    const nav = document.querySelector<HTMLButtonElement>('#nav');

    return nav != null && getComputedStyle(nav).pointerEvents === 'none';
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const isMobile = () => {
    const button = document.querySelector<HTMLButtonElement>('#nav-button');

    return button != null && getComputedStyle(button).display !== 'none';
  };

  const backFromMenu = () => {
    if (!isMobile() || isClosed()) {
      return;
    }

    navigate(-1);
    document.querySelector<HTMLButtonElement>('#nav-button')?.focus();
  };

  const navigate = useNavigate();

  const maybeGoBack: JSX.EventHandlerUnion<
    HTMLElement,
    MouseEvent,
    JSX.EventHandler<HTMLElement, MouseEvent>
  > = event => {
    if (!isMobile() || isClosed()) {
      return;
    }

    const link = event.target.closest('a');
    if (!link || link.classList.contains(styles.nav)) {
      return;
    }

    event.preventDefault();
    navigate(-1);
    requestAnimationFrame(() => {
      navigate(link.pathname + link.hash);
    });
  };

  return (
    <div class={styles.wrapper}>
      <div classList={{ [rootStyles.container]: true, [styles.menu]: true }}>
        <h1 classList={{ [styles.xmas]: isChristmas() }}>
          <SmartA href="/">
            <img
              src="/assets/logo.png"
              srcSet={generateRetinaThumbs(
                `${import.meta.env.VITE_SITE_HOST}/assets/logo.png`,
                'x48',
              )}
              alt="Rádio J-Hero"
              height={48}
            />
          </SmartA>
        </h1>
        <Tooltip
          placement="bottom"
          strategy="fixed"
          openDelay={200}
          hoverableContent={false}
          floatingOptions={{
            offset: 10,
            flip: true,
            shift: true,
          }}
        >
          <Tooltip.Trigger
            as="a"
            classList={{ [styles.button]: true, [styles.nav]: true }}
            href="#nav"
            id="nav-button"
            aria-label="Menu"
          >
            <IconTablerMenu2 />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content>
              Menu
              <Tooltip.Arrow size={10} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip>
        <nav id="nav" use:clickOutside={backFromMenu} onClick={maybeGoBack}>
          <div class={styles['menu-heading']}>
            <h2>Menu</h2>
            <Tooltip
              placement="bottom"
              strategy="fixed"
              openDelay={200}
              hoverableContent={false}
              floatingOptions={{
                offset: 10,
                flip: true,
                shift: true,
              }}
            >
              <Tooltip.Trigger
                as="a"
                classList={{ [styles.button]: true, [styles.nav]: true }}
                href="#nav-button"
                aria-label="Fechar"
                onClick={(event: Event) => {
                  event.preventDefault();
                  backFromMenu();
                }}
              >
                <IconTablerX />
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content>
                  Fechar
                  <Tooltip.Arrow size={10} />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip>
          </div>
          <ul>
            <li>
              <SmartA href="/quem-somos">Sobre</SmartA>
              <div class={styles.submenu}>
                <ul>
                  <li>
                    <SmartA href="/quem-somos">Quem Somos</SmartA>
                  </li>
                  <li>
                    <SmartA href="/equipe">Equipe</SmartA>
                  </li>
                  <li>
                    <SmartA href="/historia">História</SmartA>
                  </li>
                  <li>
                    <SmartA href="/trabalhe-conosco">Trabalhe Conosco</SmartA>
                  </li>
                  <li>
                    <SmartA href="/parceria">Fechar Parceria</SmartA>
                  </li>
                  <li>
                    <SmartA href="/no-seu-site">No Seu Site</SmartA>
                  </li>
                  <li>
                    <SmartA href="/faq">Perguntas Frequentes</SmartA>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <SmartA href="/grade">Programas</SmartA>
              <div class={styles.submenu}>
                <ul>
                  <For each={data()?.latestRadioShows}>
                    {radioShow => (
                      <li>
                        <SmartA href={`/grade#__${radioShow.slug}`}>
                          {radioShow.title}
                        </SmartA>
                      </li>
                    )}
                  </For>
                </ul>
                <SmartA href="/grade">Ver grade...</SmartA>
              </div>
            </li>
            <li>
              <SmartA href="/colunas">Colunas</SmartA>
              <div class={styles.submenu}>
                <ul>
                  <For each={data()?.latestCategories}>
                    {category => (
                      <li>
                        <SmartA href={`/${category.slug}`}>
                          {category.title}
                        </SmartA>
                      </li>
                    )}
                  </For>
                </ul>
                <SmartA href="/colunas">Ver todas...</SmartA>
              </div>
            </li>
            <li>
              <SmartA rel="external" href="/chat">
                Chat
              </SmartA>
              <div class={styles.submenu}>
                <ul>
                  <li>
                    <SmartA rel="external" href="/chat">
                      Discord
                    </SmartA>
                  </li>
                  <li>
                    <SmartA rel="external" href="/whatsapp">
                      WhatsApp
                    </SmartA>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <SmartA href="/aplicativo">App</SmartA>
              <div class={styles.submenu}>
                <ul>
                  <li>
                    <SmartA rel="external" href="/android">
                      Android
                    </SmartA>
                  </li>
                  <li>
                    <SmartA href="#">iOS (em breve)</SmartA>
                  </li>
                </ul>
                <SmartA href="/aplicativo">Mais detalhes...</SmartA>
              </div>
            </li>
            <li>
              <SmartA href="/contato">Contato</SmartA>
            </li>
          </ul>
        </nav>
        <Tooltip
          placement="bottom"
          strategy="fixed"
          openDelay={200}
          hoverableContent={false}
          floatingOptions={{
            offset: 10,
            flip: true,
            shift: true,
          }}
        >
          <Tooltip.Trigger
            as="a"
            classList={{ [styles.button]: true, [styles.search]: true }}
            href="#site-search"
            id="search-button"
            aria-label="Busca"
            onClick={(event: Event) => {
              event.preventDefault();
              location.hash = '#site-search';
              const element =
                document.querySelector<HTMLElement>('#site-search');
              const q = document.querySelector<HTMLInputElement>('#q');
              if (!element || !q) {
                return;
              }

              element.style.visibility = 'visible';
              webkitFocusTrick(q, { preventScroll: true });
            }}
          >
            <IconTablerSearch />
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content>
              Busca
              <Tooltip.Arrow size={10} />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip>
      </div>
      <Search onClose={backFromSearch} />
    </div>
  );
}
