import {
  query,
  createAsync,
  useParams,
  type RouteDefinition,
} from '@solidjs/router';

import { gql } from '~/__gql-generated__';
import CategoryHeader from '~/components/CategoryHeader';
import PostList from '~/components/PostList';
import styles from '~/routes/[category]/[year]/index.module.scss';
import { generateWidthThumbs } from '~/utils/generateThumbs';
import { client } from '~/utils/graphql';

const CATEGORY = gql(`
  query Category($slug: String!) {
    categories(where: { slug: $slug }) {
      title
      cover {
        url
      }
    }
  }
`);

const getCategory = query(async (slug: string) => {
  'use server';

  const { data } = await client.query({ query: CATEGORY, variables: { slug } });

  return {
    ...data.categories[0],
    coverSet: generateWidthThumbs(data.categories[0].cover.url, 1),
  };
}, 'category');

export const route = {
  preload: ({ location }) => {
    const pieces = location.pathname.split('/');
    return getCategory(pieces[1]);
  },
} satisfies RouteDefinition;

export default function Year() {
  const parameters = useParams();
  const data = createAsync(() => getCategory(parameters.category));

  return (
    <PostList
      filter={{
        category: { slug: parameters.category },
        publishedAt_GT: parameters.year,
        publishedAt_LT: (Number.parseInt(parameters.year, 10) + 1).toString(),
      }}
    >
      <CategoryHeader
        title={data()?.title ?? ''}
        cover={data()?.cover.url ?? ''}
        coverSet={data()?.coverSet ?? ''}
      />
      <p class={styles.filter}>
        Artigos de <b>{parameters.year}</b>
      </p>
    </PostList>
  );
}
