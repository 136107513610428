import { createVisibilityObserver } from '@solid-primitives/intersection-observer';
import { createSignal } from 'solid-js';

import styles from '~/components/BackToTop.module.scss';
import rootStyles from '~/components/Root.module.scss';
import FooterButtonGroup from '~/components/FooterButtonGroup';

function BackToTop() {
  const [intersectionRef, setIntersectionRef] = createSignal<HTMLDivElement>();
  const useIntersection = createVisibilityObserver();
  const isVisible = useIntersection(intersectionRef);

  return (
    <FooterButtonGroup ref={setIntersectionRef} class={styles.wrapper}>
      <a
        href="#app"
        classList={{ [styles.visible]: !isVisible() }}
        onClick={event => {
          event.preventDefault();
          const behavior = matchMedia('(prefers-reduced-motion: reduce)')
            .matches
            ? 'auto'
            : 'smooth';
          document.documentElement.scrollIntoView({ behavior });
          (document.activeElement as HTMLElement | null)?.blur();
        }}
      >
        <IconTablerChevronUp />
        <span class={rootStyles['sr-only']}>Voltar ao topo</span>
      </a>
    </FooterButtonGroup>
  );
}

export default BackToTop;
