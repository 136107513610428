import {
  query,
  createAsync,
  useParams,
  type RouteDefinition,
} from '@solidjs/router';
import { gql } from '~/__gql-generated__';
import CategoryHeader from '~/components/CategoryHeader';

import PostList from '~/components/PostList';
import styles from '~/routes/[category]/[year]/[month]/index.module.scss';
import { generateWidthThumbs } from '~/utils/generateThumbs';
import { client } from '~/utils/graphql';

function shiftMonth(year: number, month: number) {
  let shiftedMonth = month + 1;
  let shiftedYear = year;

  if (shiftedMonth < 1) {
    shiftedMonth = 12;
    shiftedYear--;
  }

  if (shiftedMonth > 12) {
    shiftedMonth = 1;
    shiftedYear++;
  }

  return `${shiftedYear.toString()}-${shiftedMonth.toString().padStart(2, '0')}`;
}

function formatYearMonthPair(year: string, month: string) {
  return `${year}-${month}`;
}

const CATEGORY = gql(`
  query Category($slug: String!) {
    categories(where: { slug: $slug }) {
      title
      cover {
        url
      }
    }
  }
`);

const getCategory = query(async (slug: string) => {
  'use server';

  const { data } = await client.query({ query: CATEGORY, variables: { slug } });

  return {
    ...data.categories[0],
    coverSet: generateWidthThumbs(data.categories[0].cover.url, 1),
  };
}, 'category');

export const route = {
  preload: ({ location }) => {
    const pieces = location.pathname.split('/');
    return getCategory(pieces[1]);
  },
} satisfies RouteDefinition;

export default function Month() {
  const parameters = useParams();
  const data = createAsync(() => getCategory(parameters.category));

  const nextMonth = () =>
    shiftMonth(
      Number.parseInt(parameters.year, 10),
      Number.parseInt(parameters.month, 10),
    );

  return (
    <PostList
      filter={{
        category: { slug: parameters.category },
        publishedAt_GTE: formatYearMonthPair(parameters.year, parameters.month),
        publishedAt_LT: nextMonth(),
      }}
    >
      <CategoryHeader
        title={data()?.title ?? ''}
        cover={data()?.cover.url ?? ''}
        coverSet={data()?.coverSet ?? ''}
      />
      <p class={styles.filter}>
        Artigos de{' '}
        <b>
          {new Date(
            Number.parseInt(parameters.year, 10),
            Number.parseInt(parameters.month, 10) - 1,
          ).toLocaleString(import.meta.env.VITE_LOCALE, {
            year: 'numeric',
            month: 'long',
            timeZone: import.meta.env.VITE_TZ,
          })}
        </b>
      </p>
    </PostList>
  );
}
